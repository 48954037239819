import React from "react"
import { Link } from "gatsby"
import cn from "classnames"
import styles from "./HomeHero.module.css"
import {Button} from 'gatsby-crudzoo'

interface HomeHeroDescriptionProps {
  description: string
}
export const HomeHeroDescription: React.FC<HomeHeroDescriptionProps> = props => {
  return (
    <React.Fragment>
        調べて学んだことをアウトプットしたり、趣味や気になる製品の情報を発信したりしていきます。
        <br />

        <Link to={`/blog/about`} style={{display: "block", marginTop: "24px"}}>
          <Button style={{padding: "2px 16px"}}>About Me</Button>
        </Link>
    </React.Fragment>
  )
}